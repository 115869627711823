import React, { Component } from 'react'

export default class LangToggle extends Component {
    render() {

        let lang;

        if (this.props.currentLang === "fr") {
            lang = "en"
        }else{
            lang = "fr"
        }
        return (
            <div className={this.props.deviceClass} onClick={ this.props.action }>
               <span>{lang}</span>
            </div>
        )
    }
}
