import React, { Component } from 'react'
import loadingLogo from '../assets/svg/gw_logo_notext.svg';

export default class Loading extends Component {

    constructor(props) {
        super(props)

        this.state = {
            status:true
        }
    }

    componentDidMount() {
        this.setState({status:false})
    }
    
    render() {
        
        return (
            <div className={this.state.status ? 
                "loading-screen show-loading" : 
                "loading-screen hide-loading"}>
                <img className="pulsate-bck" src={loadingLogo} alt="Loading..."/>
                <span>loading...</span>
            </div>
        )
    }
}
