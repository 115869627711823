import React, { Component } from 'react'
import gsap from "gsap";
import { ScrollTrigger,  } from "gsap/ScrollTrigger";
import { TextPlugin } from "gsap/TextPlugin";

export default class Compteurs extends Component {

    componentDidMount() {
        // GSAP Settings
        gsap.registerPlugin(ScrollTrigger, TextPlugin);
        gsap.config({ nullTargetWarn: false });
        gsap.defaults({
            ease: "Sin.easeIn",
        });
        // PLUGIN Settings
        ScrollTrigger.defaults({
            toggleActions: "play none none reverse", // DEFAULT DEHAVIOR
            scrub: false,
            markers: false // display helpers --
            //markers: {startColor: "green", endColor: "yellow", fontSize: "30px", indent: 10}
        });

        // PAGE TIMELINE
        gsap.timeline({
            scrollTrigger: {
              trigger: ".counter-container",
              start: "bottom bottom",
              end: "bottom bottom",
              scrub: false
            }
          })
          .to(".counter-container", {backgroundColor:"#F5F5F5"}, .5)

        // + COUNTERS TEXT ANIMATIONS
        gsap.to(".counter-abo span.numbers", {
            scrollTrigger: ".counter-blocks-container",
            delay:1,
            duration:1.5,
            text:{value:"350.000+ ", padSpace:false}
        });

        gsap.to(".counter-impress span.numbers", {
            scrollTrigger: ".counter-blocks-container", 
            delay:1,
            duration:2,
            text:{value:"100.000.000+", padSpace:false, ease: "none"}
        });

        gsap.to(".counter-engage span.numbers", {
            scrollTrigger: ".counter-blocks-container",
            delay:1,
            duration:1,
            text:{value:"8%+", padSpace:false}
        });
    }
    render() {
        return (
            <div className="counter-container">
                <h3>{this.props.numbersHeadline}</h3>

                <div className="counter-blocks-container"> 
                    <div className="counter-block counter-abo">
                        <span className="numbers">0</span>
                        <span className="text">{this.props.numbers1}</span>
                    </div>
                    <div className="counter-block counter-impress">
                        <span className="numbers">0</span>
                        <span className="text">{this.props.numbers2}</span>
                    </div>
                    <div className="counter-block counter-engage">
                        <span className="numbers">0</span>
                        <span className="text">{this.props.numbers3}</span>
                    </div>
                </div>
            </div>
        )
    }
}
