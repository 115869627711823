import React, {Component} from 'react'
// IMPORT SVG ICON - https://github.com/Kilian/react-bytesize-icons
import { Menu, Close } from 'react-bytesize-icons';

import gsap from "gsap";
import { TextPlugin } from "gsap/TextPlugin";
import { ScrollToPlugin } from "gsap/ScrollToPlugin"

export default class Navigation extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
             menuIsOpen:false
        }

        this.handleOpenMenu = this.handleOpenMenu.bind(this);
    }

    componentDidMount = () => {
        gsap.registerPlugin(TextPlugin, ScrollToPlugin);

        // hide side menu by default.
        this.handleOpenMenu();
    }

    // HANDLE STATE FOR SIDE MENU
    // -- TOOGLE METHOD
    handleOpenMenu() {

        this.setState(state => ({menuIsOpen: !state.menuIsOpen})); 

        if (this.state.menuIsOpen) {
            this.menuOpen(.35);   
        } else {
            this.menuClose(.35);
        }
    }

    menuOpen(duration) {
        gsap.to('.menu-deployed', duration, {y:-450, ease: "Power2.easeInOut"})
    }

    menuClose(duration) {
        gsap.to('.menu-deployed', duration, {y:0, ease: "Power2.easeInOut"})
    }

    // HANDLE MENU ITEMS 
    // - SCROLL TO SECTION METHOD
    handleMenu = (event) => {

        // On recupere le text html qui se situe sur l'element cliqué.
        let selectedItem = event.target.innerHTML;
        let section = "";
        let offset = 45;

        // Puis on scroll a la section correspondante selon les cas.
        switch (selectedItem) {
            case this.props.navHome:
                section = ".section-0";
                offset = 0
                break;
            case this.props.navOverview:
                section = ".section-1";
                offset = 45;
                break;
            case this.props.navResults:
                offset = 45;
                section = ".section-2";
                break;
            case "Shop":
                section = ".section-3";
                offset = 135;
                break;
            case "Production":
                section = ".section-4";
                offset = 89;
                break;
            case "Press &amp; contact":
                section = ".section-5";
                offset = 0;
                break;
            default:
                console.log('-- This requested section does not exist')
                break;
        }

        this.handleOpenMenu(); // Toggle Menu
        gsap.to(window, {duration: 1, scrollTo:{y:section, offsetY:offset}}) // on scroll vers notre section
    }

    render() {

        return (
            <div className="side-col">
                <div className="menu">
                    <div className="menu-icon" onClick={this.handleOpenMenu}>
                        {this.state.menuIsOpen ? 
                        <Menu className="icon animate__animated animate__fadeIn" /> : 
                        <Close className="icon-close animate__animated animate__rotateIn" />
                        }
                    </div>
                    
                    <div className="menu-info"><span className="menu-vertical">{this.props.navHome}</span></div>
                    
                    <div className="menu-scroll">
                        <div className="icon-scroll"></div>
                    </div>
                </div>
                <div className={this.state.menuIsOpen ? 
                    "menu-deployed menu-open" : 
                    "menu-deployed menu-close"}>
                    <span onClick={this.handleMenu}>{this.props.navHome}</span>
                    <span onClick={this.handleMenu}>{this.props.navOverview}</span>
                    <span onClick={this.handleMenu}>{this.props.navResults}</span>
                    <span onClick={this.handleMenu}>Shop</span>
                    <span onClick={this.handleMenu}>Production</span>
                    <span onClick={this.handleMenu}>Press &amp; contact</span>
                </div>
            </div>
        )
    }
}
