import React, { Fragment, Component } from 'react';
import {BrowserView, MobileView} from "react-device-detect";

import '../src/assets/scss/normalize.scss';
import '../src/assets/scss/animate.css';
import '../src/assets/scss/styles.scss';

// - COMPONENTS
// ---  UI
import Loading from './components/Loading';
import LangToggle from './components/LangToggle';
import Header from './components/Header';
import Navigation from './components/Navigation';

// --- SECTIONS
import Accueil from './components/Accueil';
import Presentation from './components/Presentation';
import Compteurs from './components/Compteurs';
import Scores from './components/Scores';
import Merchandising from './components/Merchandising';
import Production from './components/Production';
import Footer from './components/Footer';

// ---  DEVICES LAYOUTS - Dédiée phone / tablette.
import DeviceLayout from './components/DevicesLayout';


export default class App extends Component {

  constructor() {
    super();
    
    this.state = { // STATE LANG GLOBAL ENVOYÉ EN PROPOS A NOS COMPOSANTS
      lang: "", // DEFAULT BASED ON BROWSER LANG PROPERTY
      navHome:"",
      navOverview:"",
      navResults: "",
      accueilHeadline: "",
      accueilSubline: "",
      accueilTrophyTitle: "",
      accueilTrophyLine1: "",
      accueilTrophyLine2: "",
      accueilTrophyLine3: "",
      accueilTrophyLine4: "",
      accueilTrophyCta: "",
      presHeadline: "",
      presSubline1: "",
      presSubline2: "",
      presH2: "",
      presPartBoulogne1: "",
      presPartBoulogne2: "",
      presPartBoulogne3: "",
      presPartLogting1: "",
      presPartLogting2: "",
      presPartLogting3: "",
      presPartFuze1: "",
      presPartFuze2: "",
      presPartFuze3: "",
      numbersHeadline: "",
      numbers1: "",
      numbers2: "",
      numbers3: "",
      merchText1: "",
      merchText2: "",
      prodH3:"",
      prodH2:"",
      prodP:"",
      footerH1:"",
      footerP:"",
      footerCta:"",
      legalHeadline1:"",
      legal1a:"",
      legal1b:"",
      legal1c:"",
      legal1d:"",
      legalHeadline2:"",
      legal2a:"",
      legal2b:"",
      legalHeadline3:"",
      legal3a:"",
      legal3b:"",
      legal3c:"",
      legalHeadline4:"",
      legal4a:"",
      legal4b:""
    }
  }

  componentDidMount() {
    let defaultLang;
    if(navigator.language === "fr") {
      defaultLang = "fr"
    } else{
      defaultLang = "en"
    }
    // DEFINE LANG
    this.setState({lang: defaultLang})

    // INIT LANG METHOD TO LOAD OUR LANG TEXTS VARIABLES
    this.switchLang(); 
    //console.log("browser navigator setting : " + navigator.language)
  }
  
  // LANG SETTINGS
  switchLang = () => {
    if (this.state.lang === 'fr') {
      this.setState({
        lang: "en",

        // NAVIGATION
        navHome:"Home",
        navOverview:"Overview",
        navResults: "Results",

        // ACCUEIL
        accueilHeadline: "Create the most beautiful stories in the world of esports",
        accueilSubline: "As a global esports brand, GameWard gathers professional players and content creators to inspire the future by offering the best possible experiences to the fans.",
        accueilTrophyTitle: "Latest major results",
        accueilTrophyLine1: "Top 3 Europe [EU Masters] - League of Legends ",
        accueilTrophyLine2: "Top 10 Europe - Apex Legends",
        accueilTrophyLine3: "Champion de la Zrt TM Cup [Bercy]",
        accueilTrophyLine4: "Trackmania - Champion de France - NBA2K",
        accueilTrophyCta: "Latest results",

        // PRESENTATION
        presHeadline: "Guide esports in the right direction",
        presSubline1: "Since 5 years, GameWard was able to be distinguished as one of the most prominent esports clubs in France. Based on trust, intensity and boldness among other values, our team aspires to become a responsible player in everyone’s self-fulfillment through esports and entertainment.",
        presSubline2: "As the official Boulogne-Billancourt city esports club, GameWard offers one of the best performance ecosystems in Europe, which allows us to play at the highest french and international level in games such as League of Legends, Fortnite, NBA2K, or Trackmania.",
        presH2: "Our Partners",
        presPartBoulogne1: "The city of Boulogne-Billancourt entrusted us with being their official esports club.",
        presPartBoulogne2: "Since January 2020 GameWard has settled in a brand new training center in the heart of the city, allowing us to welcome all of our players and staff.",
        presPartBoulogne3: "This partnership is a perfect way to make esports more accessible to a wider audience, set up educational actions, and lead and support young players in their esports activities.",
        presPartLogting1: "Lofting is a high-end gym established in Boulogne-Billancourt for the past 30 years, offering what is best in sports coaching.",
        presPartLogting2: "In a classy and connected space, Lofting’s several dedicated coaches help their members with the utmost rigor to achieve their personal goals.",
        presPartLogting3: "Offering such sports support and facilities is a source of pride for the club. In that sense, we are truly happy to welcome Lofting as one of our partners.",
        presPartFuze1: "Fuze Forge is both a video game platform offering a subscription to get access to several games, and an entertainment platform dedicated to gaming.",
        presPartFuze2: "Thanks to the Fuze Forge esports app, fans can experience all major tournaments live and support us through a result prediction system.",
        presPartFuze3: "As a major partner for over a year, Fuze Forge wishes to help strengthen GameWard’s spot as a key team and establish itself as a major player in French esports.",

        // COMPTEURS
        numbersHeadline: "Key figures about the GameWard professional esport club and its players",
        numbers1: "subscribers",
        numbers2: "impressions per year",
        numbers3: "engagement",

        // MERCH
        merchText1: "Whatever your dream may be…",
        merchText2: "Cherish it",

        // PRODUCTION
        prodH3:"Latest videos",
        prodH2:"Thanks to a group of creative individuals, GameWard produces daily content for the fans.",
        prodP:"Browse through our video productions on Youtube. We also suggest you to follow our Twitch channel to know when we broadcast live.",

        // FOOTER
        footerH1:"Press-Kit",
        footerP:"Get access to our files online",
        footerCta:"Download",

        // LEGALES -  inside footer
        legalHeadline1: "- LEGAL INFORMATIONS",
        legal1a:"The website https://gameward.team/ is published and produced by SIGMA Esports SAS, registered at RCS (Paris Trade and Companies Register) on the 07-12-2017 under the SIREN number 833 830 250, and whose head office is located at 113 rue du Point du Jour 92100 Boulogne-Billancourt, France.",
        legal1b:"The website’s developer is Sisyphe Digital.",
        legal1c:"The website is hosted by Ionos.",
        legal1d:"Contact : contact@gameward.team",
        legalHeadline2:"- INTELLECTUAL PROPERTY",
        legal2a:"GameWard is a registered trademark of SIGMA Esports company.",
        legal2b:"Any reproduction of the GameWard brand and logo, the pictures and images found on the https://gameward.team/ and https://shop.gameward.team/ websites, partially or fully, is strictly forbidden. The products found in this catalog may be subject to change.",
        legalHeadline3:"- COOKIES AND TRACKERS",
        legal3a:"The website https://gameward.team/ has an audience measurement system.",
        legal3b:"In accordance with the European directive « Telecoms Package », Internet users must be informed and give their consent before placing trackers (more commonly known as « cookies »). Internet users must be given the choice to no be tracked when visiting a website or using an application.",
        legal3c:"Website publishers therefore have the obligation to seek prior consent from users. Refusing to install a cookie may make it impossible to access certain services. Users may however setup their internet browser to automatically refuse cookie installation.",
        legalHeadline4:"- AUDIENCE MEASUREMENT",
        legal4a:"Audience Measurement cookies are small files that allow the gathering and analyzing of traffic statistics on the website: pages visited, number of visits, bounce rate, origin of the visits… Audience measurement cookies are completely anonymous.",
        legal4b:"On this website, Google Analytics was chosen as our way to measure audience."

      })
    } else {
      this.setState({
        lang: "fr",

        // NAVIGATION
        navHome:"Accueil",
        navOverview:"Présentation",
        navResults: "Scores",

        // ACCUEIL
        accueilHeadline: "Créer les plus belles histoires de l’esports",
        accueilSubline: "Marque esports globale, GameWard réunit joueurs professionnels et créateurs de contenus dans l’unique but d’inspirer l’avenir en offrant aux fans les meilleures expériences possibles.",
        accueilTrophyTitle: "Dernières performances majeures",
        accueilTrophyLine1: "Top 3 Europe [EU Masters] - League of Legends ",
        accueilTrophyLine2: "Top 10 Europe - Apex Legends",
        accueilTrophyLine3: "Champion de la Zrt TM Cup [Bercy]",
        accueilTrophyLine4: "Trackmania - Champion de France - NBA2K",
        accueilTrophyCta: "Derniers résultats",

        // PRESENTATION
        presHeadline: "Accompagner l’esports dans la bonne direction",
        presSubline1: "Depuis 5 ans, le club GameWard s’est positionné comme l’une des références majeures en France. Basée sur des valeurs de confiance, d’intensité ou encore d’audace, l’équipe aspire à devenir un acteur responsable dans l’accomplissement de chacun à travers le divertissement lié à l’esports.",
        presSubline2: "GameWard est aujourd’hui le club esports résident officiel de Boulogne-Billancourt et dispose notamment d’un des meilleurs écosystèmes de performance d’Europe, ce qui lui permet de jouer au plus haut niveau français et international sur des jeux comme League of Legends, Fortnite, NBA2K, ou aussi Trackmania.",
        presH2: "Nos partenaires",
        presPartBoulogne1: "La ville de Boulogne-Billancourt nous accorde toute sa confiance pour devenir officiellement son équipe esport résidente. ",
        presPartBoulogne2: "GameWard s’est installée en janvier 2020 dans son tout nouveau centre d’entraînement situé au coeur de la ville. Il permet d’accueillir l’ensemble de ses joueurs et le personnel.",
        presPartBoulogne3: "Ce partenariat permet d’accroitre la démocratisation du secteur auprès du plus grand nombre, de mettre en place des actions pédagogiques et d’encadrer ainsi qu’accompagner les jeunes joueurs dans leur pratique esportive.",
        presPartLogting1: "Salle de sport haut de gamme, Lofting est implantée à Boulogne-Billancourt depuis plus de 30 ans et représente ce qu’il y a de meilleur dans le coaching sportif.",
        presPartLogting2: "Dans un espace classieux et connecté, Lofting et ses nombreux coachs spécialisés accompagnent leurs adhérents avec la plus grande rigueur pour les aider à atteindre leurs objectifs personnels.",
        presPartLogting3: "Proposer un tel encadrement sportif est une fierté pour le club. En ce sens, nous sommes véritablement heureux de pouvoir accueillir Lofting en tant que partenaire de GameWard.",
        presPartFuze1: "Fuze Forge est une plateforme de jeu vidéo proposant un abonnement permettant l’accès à de nombreux jeux, mais également une plateforme de divertissement dédiée à l’univers du gaming.",
        presPartFuze2: "Grâce à l’application Fuze Forge esport, les fans peuvent vivre les grandes compétitions en direct et nous soutenir à travers un système de pronostiques.",
        presPartFuze3: "La volonté de Fuze Forge en tant que partenaire majeur depuis plus d’un an, est de consolider la place qu’a GameWard parmi les équipes incontournables et de s’installer durablement comme un acteur majeur de l’esport français.",

        // COMPTEURS
        numbersHeadline: "Quelques chiffres clés sur le club esport professionnel GameWard et ses joueurs",
        numbers1: "abonnés",
        numbers2: "impressions par an",
        numbers3: "d’engagements",

        // MERCH
        merchText1: "Quel que soit ton rêve...",
        merchText2: "Prends-en soin",

        // PRODUCTION
        prodH3:"Dernières vidéos",
        prodH2:"Grâce à son équipe créative, GameWard produit du contenu au quotidien pour ses fans",
        prodP:"Parcourez l'ensemble de nos créations vidéos via notre chaîne Youtube. Nous vous invitons également à suivre notre chaîne Twitch afin de suivre nos retransmissions en direct.",

        // FOOTER
        footerH1:"Press-Kit",
        footerP:"Accéder directement à nos fichiers en ligne",
        footerCta:"Télécharger",

        // LEGALES -  inside footer
        legalHeadline1: "- INFORMATIONS LÉGALES",
        legal1a:"Le site https://gameward.team/ est édité et produit par la société SIGMA Esports SAS, immatriculée au RCS le 07-12-2017 sous le numéro SIREN 833 830 250 et dont le siège social est situé au 113 rue du Point du Jour 92100 Boulogne Billancourt.",
        legal1b:"Le développeur du site est la société Sisyphe Digital.",
        legal1c:"Le site est hébergé par la société Ionos.",
        legal1d:"Contact : contact@gameward.team",
        legalHeadline2:"- PROPRIÉTÉ INTELLECTUELLE",
        legal2a:"GameWard est une marque déposée de la société SIGMA Esports.",
        legal2b:"Toute reproduction de la marque et du logo GameWard, des photographies et des images du site https://gameward.team/ et https://shop.gameward.team/, partielle ou intégrale, est strictement interdite. Les produits présentés sur ce catalogue peuvent être amenés à subir des modifications.",
        legalHeadline3:"- COOKIES ET TRACEURS",
        legal3a:"Le site internet https://gameward.team/ possède un système de mesure d’audience.",
        legal3b:"En application de la directive européenne dite « paquet télécom », les internautes doivent être informés et donner leur consentement préalablement à l’insertion de traceurs (plus couramment appelés « cookies »). Les internautes doivent disposer d’une possibilité de choisir de ne pas être tracés lorsqu’ils visitent un site ou utilisent une application. Les éditeurs de sites internet ont donc l’obligation de solliciter au préalable le consentement des utilisateurs.",
        legal3c:"Le refus d’installation d’un cookie peut entraîner l’impossibilité d’accéder à certains services. L’internaute peut toutefois configurer son navigateur internet pour refuser l’installation des cookies.",
        legalHeadline4:"- MESURE D'AUDIENCE",
        legal4a:"Les cookies de mesure d’audience sont de petits fichiers qui permettent de connaitre et d’analyser les statistiques de trafic sur le site internet : les pages visitées, le nombre de visites, le taux de rebond, la provenance des visites,… Les cookies de mesure d’audience sont totalement anonymes.",
        legal4b:"Sur ce site, c’est la solution Google Analytics qui est utilisée pour mesurer l’audience."
      })
    }
  }

  render() {

    return (
      <Fragment>

        <BrowserView>

        <Loading />
        <div className="container"> 
            <LangToggle
              deviceClass="lang-toggle-desk"
              currentLang={this.state.lang}
              action={()=>this.switchLang()}
            />
            <Header />
            <Navigation 
              navHome={this.state.navHome}
              navOverview={this.state.navOverview}
              navResults={this.state.navResults}
            />
            <Accueil
              navHome={this.state.navHome}
              headline={this.state.accueilHeadline}
              subline={this.state.accueilSubline}
              accueilTrophyTitle={this.state.accueilTrophyTitle}
              accueilTrophyLine1={this.state.accueilTrophyLine1}
              accueilTrophyLine2={this.state.accueilTrophyLine2}
              accueilTrophyLine3={this.state.accueilTrophyLine3}
              accueilTrophyLine4={this.state.accueilTrophyLine4}
              accueilTrophyCta={this.state.accueilTrophyCta}
              />
            <Presentation
              navHome={this.state.navHome}
              navOverview={this.state.navOverview}
              presHeadline={this.state.presHeadline}
              presSubline1={this.state.presSubline1}
              presSubline2={this.state.presSubline2}
              presH2={this.state.presH2}
              presPartBoulogne1={this.state.presPartBoulogne1}
              presPartBoulogne2={this.state.presPartBoulogne2}
              presPartBoulogne3={this.state.presPartBoulogne3}
              presPartLogting1={this.state.presPartLogting1}
              presPartLogting2={this.state.presPartLogting2}
              presPartLogting3={this.state.presPartLogting3}
              presPartFuze1={this.state.presPartFuze1}
              presPartFuze2={this.state.presPartFuze2}
              presPartFuze3={this.state.presPartFuze3}
            />
            <Compteurs
              numbersHeadline={this.state.numbersHeadline}
              numbers1={this.state.numbers1}
              numbers2={this.state.numbers2}
              numbers3={this.state.numbers3}
            />
            <Scores
              navOverview={this.state.navOverview}
              navResults={this.state.navResults}
              accueilTrophyCta={this.state.accueilTrophyCta}
            />
            <Merchandising
              merchText1={this.state.merchText1}
              merchText2={this.state.merchText2}
            />
            <Production
              prodH3={this.state.prodH3}
              prodH2={this.state.prodH2}
              prodP={this.state.prodP}
            />
            <Footer
              footerH1={this.state.footerH1}
              footerP={this.state.footerP}
              footerCta={this.state.footerCta}
              legalHeadline1={this.state.legalHeadline1}
              legal1a={this.state.legal1a}
              legal1b={this.state.legal1b}
              legal1c={this.state.legal1c}
              legal1d={this.state.legal1d}
              legalHeadline2={this.state.legalHeadline2}
              legal2a={this.state.legal2a}
              legal2b={this.state.legal2b}
              legalHeadline3={this.state.legalHeadline3}
              legal3a={this.state.legal3a}
              legal3b={this.state.legal3b}
              legal3c={this.state.legal3c}
              legalHeadline4={this.state.legalHeadline4}
              legal4a={this.state.legal4a}
              legal4b={this.state.legal4b}
            />
          </div>  

        </BrowserView>

        <MobileView>
          <DeviceLayout />
        </MobileView>

      </Fragment>
    )
  }
}
