import React, { Component } from 'react'
import gsap from "gsap";
import { ScrollTrigger,  } from "gsap/ScrollTrigger";
import { TextPlugin } from "gsap/TextPlugin";
import { Plus } from 'react-bytesize-icons';

export default class Production extends Component {

    componentDidMount() {

        // GSAP Settings
        gsap.registerPlugin(ScrollTrigger, TextPlugin);
        gsap.config({ nullTargetWarn: false });
        gsap.defaults({
            ease: "Sin.easeIn"
        });
        // PLUGIN Settings
        ScrollTrigger.defaults({
            toggleActions: "play none none reverse", // DEFAULT DEHAVIOR
            scrub: false,
            markers: false // display helpers --
            //markers: {startColor: "blue", endColor: "yellow", fontSize: "30px", indent: 10}
        });

        // PAGE TIMELINE
        gsap.timeline({
            scrollTrigger: {
              trigger: ".prod-container",
              start: "center bottom",
              end: "top top",
              scrub: false
            }
          })
          .to(".menu-info span", {duration: .1, text:{value:"Production", padSpace:false} })
          .to(".prod-behind", {y:-45, x:45}, .15)
          .from(".prod-side-title", {width:0}, .3) 
          .from(".prod-embed-youtube", {y:90, opacity:0}, .6)
          .from(".prod-featured-videos", {y:135, opacity:0}, .9)
          .from(".prod-side-title h3", {opacity:0, delay:.9}, .3) 
    }

    render() {

        const handleRedirection = (url) => {
            window.open(url, '_blank');
        }

        return (
            <div className="container">

                <div className="prod-container section-4">

                    <div className="prod-side-bar"></div>

                    <div className="prod-side-title">
                        <h3>{this.props.prodH3}</h3>
                    </div>

                    <div className="prod-embed-youtube">

                        <div className="button-more-videos" onClick={()=>handleRedirection('https://www.youtube.com/watch?v=M49M4Z3o47U')}>
                            <Plus className="icon" />
                        </div>

                        <iframe src="https://www.youtube.com/embed/M49M4Z3o47U" title="M49M4Z3o47U" frameborder="0" allowfullscreen></iframe>
                        <h2>{this.props.prodH2}</h2>
                        <p>{this.props.prodP}</p>
                    </div>

                    <div className="prod-featured-videos">
                        <div className="video-thumb">
                        <iframe src="https://www.youtube.com/embed/6PTJkTCqayw" title="6PTJkTCqayw" frameborder="0" allowfullscreen></iframe>
                        </div>
                        <div className="video-thumb prod-featured-pos-2">
                        <iframe title="3due2XY7b_c" src="https://www.youtube.com/embed/3due2XY7b_c" frameBorder="0" allowFullScreen></iframe>
                        </div>
                    </div>

                </div>

                <div className="prod-behind"></div>

            </div>
        )
    }
}
