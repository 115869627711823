import React, { Component } from 'react'
import gsap from "gsap";
import { ScrollTrigger,  } from "gsap/ScrollTrigger";
import { TextPlugin } from "gsap/TextPlugin";
import Gwardian from './Gwardian'
import Faceeverything from '../assets/png/gw_faceeverything.png';
import { Calendar } from 'react-bytesize-icons';

export default class Scores extends Component {

    state = {
        data: [],     // JSON DATA FROM PUBLIC/DATA FOLDER
    }

    componentDidMount() {

        // GET JSON SCORES DATA ( PUBLIC/DATA/SCORES.JSON )
        fetch('./data/scores.json')
        .then(response => response.json())
        .then(data => this.setState({ data }));

        // GSAP Settings
        gsap.registerPlugin(ScrollTrigger, TextPlugin);
        gsap.config({ nullTargetWarn: false });
        gsap.defaults({
            ease: "Sin.easeIn"
        });
        
        // PLUGIN Settings
        ScrollTrigger.defaults({
            toggleActions: "play none none reverse", // DEFAULT DEHAVIOR
            scrub: false,
            markers: false
        });

        // PAGE TIMELINE
        gsap.timeline({
            scrollTrigger: {
              trigger: ".scores-container",
              toggleActions: "restart none none reverse", // DEFAULT DEHAVIOR
              start: "center bottom",
              end: "-45px top",
              scrub: false
            }
          })
          .eventCallback("onStart", this.menuResultsStart)
          .eventCallback("onReverseComplete", this.menuResultsEnd)
          .to("header", {backgroundColor:"#ffffff"}, .1)
          .from(".scores-board-container", {y: 90}, .25)
          .from(".scores-behind", {y: -90, x: 135}, .2)
          .from(".scores-gwardian", { y: 45, x:90, rotation:-2, opacity:0}, .6)
          .from(".scores-face-everything img", {opacity:0}, .8)
          .from(".side-title", {delay:1, opacity:0, width: 135}, .25)
          .from(".side-title h3", {delay:1.3, opacity:0}, .25);
    }

    menuResultsStart = () => {
        gsap.to(".menu-info span", {duration: 0, text: this.props.navResults, ease: "none"});
    }

    menuResultsEnd = () => {
        gsap.to(".menu-info span", {duration: 0, text: this.props.navOverview, ease: "none"});
    }
    
    render() {

        return (
            <div className="container section-2">

                <div className="scores-container">

                    <div className="side-title">
                        <h3>{this.props.accueilTrophyCta}</h3>
                    </div>

                    <div className="scores-gwardian">
                        <Gwardian posture="side" />
                    </div>

                    <div className="scores-board-container">

                        <div className="scores-face-everything">
                            <img src={Faceeverything} alt="Face Everything"/>
                        </div>

                        { // LOOP SCORES
                        this.state.data.map(elem =>

                        <div className="score-line" key={elem[0]}>

                            <div className={"score-line-logo " + elem[1]}></div>

                            <div className="score-line-date">
                                <Calendar className="icon" />  
                            <span>{elem[2]}</span>
                            </div>

                            <div className="score-line-titre">
                                <span>{elem[3]}</span>
                            </div>

                            <div className="score-line-info">
                                <span>{elem[4]}</span>
                            </div>

                            <div className={"score-line-top " + elem[6]}>
                                {/* <span className={elem[6]}>top</span> */}
                                <span className={elem[6]}>{elem[5]}</span>
                            </div>
                        </div>
                        )}
                       

                        <div className="score-board-behind"></div>

                        {/* <div className="score-button" onClick={()=>handleRedirection('https://www.youtube.com/channel/UCbzGPPJEWpbk2cDk1s5fscw')}>
                            <span className="score-button-name">voir tous les résultats</span>
                            <span className="score-button-icon"><Plus className="icon"/></span>
                        </div> */}

                    </div>

                    <div className="scores-behind"></div>
                </div>
               
            </div>
        )
    }
}
