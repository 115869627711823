import React from 'react'
import Logo from '../assets/svg/gw_logo.svg'

export default function Header() {
    return (
        <header>
            <div className="logo">
                <img src={Logo} alt="Gameward"/>
            </div>
        </header>
    )
}