
import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'

// IMPORTS SVG LOGO
import Discord from '../assets/svg/icon_social_discord.svg';
import Facebook from '../assets/svg/icon_social_facebook.svg';
import Instagram from '../assets/svg/icon_social_instagram.svg';
import Linkedin from '../assets/svg/icon_social_linkedin.svg';
import Tiktok from '../assets/svg/icon_social_tiktok.svg';
import Twitch from '../assets/svg/icon_social_twitch.svg';
import Twitter from '../assets/svg/icon_social_twitter.svg';
import Youtube from '../assets/svg/icon_social_youtube.svg';
import Threads from '../assets/svg/icon_social_threads.svg';


export default class Social extends Component {
    
    render() {

        const handleRedirection = (url) => {
            window.open(url, '_blank');
        }

        return (
            <div className="social-col">

                <div className="icon-container">
                    
                    <div className="icon-block">
                        <ReactSVG src={Discord} className="icon"
                            onClick={()=>handleRedirection('http://discord.gg/gameward')} />
                    </div>
                    <div className="icon-block">
                        <ReactSVG src={Facebook} className="icon" 
                            onClick={()=>handleRedirection('https://www.facebook.com/GameWardTeam/')} />
                    </div>
                    <div className="icon-block">
                        <ReactSVG src={Instagram} className="icon" 
                            onClick={()=>handleRedirection('https://www.instagram.com/gamewardteam/?hl=fr')} />
                    </div>
                    <div className="icon-block">
                        <ReactSVG src={Linkedin} className="icon" 
                            onClick={()=>handleRedirection('https://www.linkedin.com/company/gameward')} />
                    </div>
                    <div className="icon-block">
                        <ReactSVG src={Tiktok} className="icon" 
                            onClick={()=>handleRedirection('https://www.tiktok.com/@gameward.team')} />
                    </div>
                    <div className="icon-block">
                        <ReactSVG src={Twitch} className="icon" 
                            onClick={()=>handleRedirection('https://www.twitch.tv/gameward_tv')} />
                    </div>
                    <div className="icon-block">
                        <ReactSVG src={Twitter} className="icon" 
                            onClick={()=>handleRedirection('https://twitter.com/GameWardTeam')} />
                    </div>
                    <div className="icon-block">
                        <ReactSVG src={Youtube} className="icon" 
                            onClick={()=>handleRedirection('https://www.youtube.com/channel/UCbzGPPJEWpbk2cDk1s5fscw')} />
                    </div>
                    <div className="icon-block outline">
                        <ReactSVG src={Threads} className="icon" 
                            onClick={()=>handleRedirection('https://www.threads.net/@gamewardteam')} />
                    </div>

                </div>

            </div>
        )
    }
}
