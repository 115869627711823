import React, { Component} from 'react'

import GwardianFront from '../assets/png/gwardian_stand_front.png'
import GwardianSide from '../assets/png/gwardian_stand_side.png'
import GwardianBack from '../assets/png/gwardian_stand_back.png'

// on recoit en props l'asset a afficher FRONT ou `BACK selon le model du gwardian a afficher.
export default class Gwardian extends Component {

    render() {

        if(this.props.posture === "front") {
            return (
                // <div id="bgglitch"></div>
                <img src={GwardianFront} alt="Gwardian"/>
            )
        }

        if(this.props.posture === "side") {
            return (
                <img src={GwardianSide} alt="Gwardian"/>
            )
        }

        if(this.props.posture === "back") {
            return (
                <img src={GwardianBack} alt="Gwardian"/>
            )
        }
    }
}