import React, { Component} from 'react'

import gsap from "gsap";
import { ScrollTrigger  } from "gsap/ScrollTrigger";

import Social from './Social';

import Gwardian from './Gwardian'
import { ChevronBottom } from 'react-bytesize-icons';

//import IconTrophyTmc from '../assets/png/logo_trophy_tmc.png'
//import IconTrophyLfl from '../assets/png/logo_trophy_lfl2020.png'
//import IconTrophyFncs from '../assets/png/logo_trophy_fncs.png'
//import IconTrophyEuMasters from '../assets/png/logo_trophy_eu-masters.png'
import IconTrophyNba from '../assets/png/logo_trophy_nba2k.png'
import IconTrophyLflSummer from '../assets/png/logo_trophy_lfl-summer-split.png'
import IconTrophyApex from '../assets/png/logo_trophy_apexlegends.png'
import IconTrophyZTmCup from '../assets/png/logo_trophy_zrt-tm-cup.png'

export default class Accueil extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
             scrollSection: false // state sidecol menu icon position
        }
    }
    
    componentDidMount() {
        

        gsap.registerPlugin(ScrollTrigger);
        gsap.config({ nullTargetWarn: false });
        gsap.defaults({
            ease: "Sin.easeIn",
            duration: .8
        });

        ScrollTrigger.create({
            trigger: ".section-0",
            start: "center bottom",
            end: "center top",
            markers: false, // display helpers --
            onToggle: section => this.setState({scrollSection: section.isActive}) // state condition: boolean - animation icon scroll :: section.isActive return true / false
          });
        
         // ACCUEIL STARTING ANIMATIONS
         const tlAcc = gsap.timeline();
         //tlAcc.eventCallback("onComplete", this.completeHandler)
         tlAcc.to("header", {backgroundColor:"#FFFFFF"}, .1)
         tlAcc.from(".home-intro-text", {opacity: 0, y:80}, .8);
         tlAcc.from(".home-intro-trophy", {opacity:0, y:80}, 1.2);
         tlAcc.from(".home-gwardian", {delay:.8, opacity:0, y:135, x:-135, scale:1.5, ease: "Circ.easeIn"}, 1.5)
         tlAcc.play();

         this.handleMenuSidePosition();
    }

    scrollToScores = () => { // HANDLE CTA TROPHY METHOD
        const offset = 45;
        const section = ".section-2";
        gsap.to(window, {duration: 1, scrollTo:{y:section, offsetY:offset}})
    }

    menuScrollOpen() {
        gsap.to('.side-col', .35, {y:0, ease: "Power2.easeInOut"})
    }

    menuScrollClose(){
        gsap.to('.side-col', .35, {y:90, ease: "Power2.easeInOut"})
    }

    handleMenuSidePosition() {

        // on cache ou pas l'icon de scrolling selon notre state 
        // ne s'affiche que sur sur ce component - section presentation.

        if (this.state.scrollSection) {
            this.menuScrollOpen();
        } else {
            this.menuScrollClose();
        }
    }

    
    render() {


        return (

            <div className="content section-0">

                <div className="home-intro-container">

                    <div className="home-intro-text">
                        <h1>{this.props.headline}</h1>
                        <p>{this.props.subline}</p>
                    </div>

                    <div className="home-intro-trophy">

                        <div className="trophy-title" onClick={this.props.action}>
                            <h3>{this.props.accueilTrophyTitle}</h3>
                        </div>

                        <div className="trophy-container">
                            <div className="trophy-icon">
                                <img src={IconTrophyLflSummer} alt="tmc"/>
                            </div>
                            <div className="trophy-info">
                                <span>{this.props.accueilTrophyLine1}</span>
                            </div>
                        </div>

                        <div className="trophy-container">
                            <div className="trophy-icon">
                            <img src={IconTrophyApex} alt="tmc"/>
                            </div>
                            <div className="trophy-info">
                                <span>{this.props.accueilTrophyLine2}</span>
                            </div>
                        </div>
                        
                        <div className="trophy-container">
                            <div className="trophy-icon">
                            <img src={IconTrophyNba} alt="tmc"/>
                            </div>
                            <div className="trophy-info">
                                <span>{this.props.accueilTrophyLine4}</span>
                            </div>
                        </div>

                        <div className="trophy-container">
                            <div className="trophy-icon">
                            <img src={IconTrophyZTmCup} alt="tmc"/>
                            </div>
                            <div className="trophy-info">
                                <span>{this.props.accueilTrophyLine3}</span>
                            </div>
                        </div>


                        <div className="trophy-cta" onClick={this.scrollToScores}>
                            <span>{this.props.accueilTrophyCta}</span>
                            <div className="trophy-cta-icon">
                                <ChevronBottom className="icon" />
                            </div>
                        </div>

                    </div>
                  
                </div>

                <div className="home-gwardian">
                    <Gwardian posture="front" />
                </div>

                <div className="home-gwardian-text"></div>

                <Social />
  
            </div>
        )
    }
}
